import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { ref, get } from "firebase/database";
import { Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";

// Registrando os componentes do Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Main = () => {
  const [todasEncomendas, setTodasEncomendas] = useState([]);
  const [clientes, setClientes] = useState([]);

  // Função para buscar as encomendas no Firebase
  const fetchEncomendas = async () => {
    const encomendasRef = ref(db, "encomendas");
    try {
      const snapshot = await get(encomendasRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const encomendasList = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setTodasEncomendas(encomendasList);
      } else {
        console.log("Nenhuma encomenda encontrada");
      }
    } catch (error) {
      console.error("Erro ao buscar encomendas:", error);
    }
  };

  // Função para buscar os clientes no Firebase
  const fetchClientes = async () => {
    const clientesRef = ref(db, "clientes");
    try {
      const snapshot = await get(clientesRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const clientesList = Object.keys(data).map((key) => ({
          id: key,
          nome: data[key].nome,
        }));
        setClientes(clientesList);
      } else {
        console.log("Nenhum cliente encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
    }
  };

  useEffect(() => {
    fetchEncomendas();
    fetchClientes();
  }, []);

  // Contando o número de encomendas por status
  const contarStatus = () => {
    const statusCount = {};

    todasEncomendas.forEach((encomenda) => {
      const status = encomenda.status;
      if (status) {
        statusCount[status] = (statusCount[status] || 0) + 1;
      }
    });

    return statusCount;
  };

  // Calculando os ganhos por mês para encomendas com status "pago"
  const ganhosPorMes = () => {
    const ganhos = {};

    todasEncomendas.forEach((encomenda) => {
      // Verifica se a encomenda está "paga"
      if (encomenda.status === "pago") {
        const dataEntrega = new Date(encomenda.dataEntrega);
        const mesAno = `${
          dataEntrega.getMonth() + 1
        }/${dataEntrega.getFullYear()}`;

        if (!ganhos[mesAno]) {
          ganhos[mesAno] = 0;
        }

        ganhos[mesAno] += encomenda.subtotal;
      }
    });

    // Organizando os ganhos por mês em ordem crescente
    const ganhosOrdenados = Object.keys(ganhos)
      .sort((a, b) => {
        const [mesA, anoA] = a.split("/");
        const [mesB, anoB] = b.split("/");
        return new Date(`${anoA}-${mesA}-01`) - new Date(`${anoB}-${mesB}-01`);
      })
      .reduce((acc, key) => {
        acc[key] = ganhos[key];
        return acc;
      }, {});

    return ganhosOrdenados;
  };

  const statusCount = contarStatus();
  const ganhos = ganhosPorMes();

  const meses = Object.keys(ganhos);
  const valoresGanhos = Object.values(ganhos);

  // Dados do gráfico de status das encomendas (gráfico de pizza)
  const dataStatus = {
    labels: Object.keys(statusCount), // Usa os status encontrados no Firebase
    datasets: [
      {
        label: "Status das Encomendas",
        data: Object.values(statusCount), // Conta os valores dinamicamente
        backgroundColor: [
          "#20B8A6",
          "#F6C23E",
          "#1CC88A",
          "#FF5733",
          "#A9A9A9",
        ], // Adicionando cores para novos status
        borderColor: ["#20B8A6", "#F6C23E", "#1CC88A", "#FF5733", "#A9A9A9"],
        borderWidth: 1,
      },
    ],
  };

  // Dados do gráfico de ganhos por mês
  const dataGanhos = {
    labels: meses,
    datasets: [
      {
        label: "Ganhos do Mês",
        data: valoresGanhos,
        backgroundColor: "rgb(20 184 166)",
        borderColor: "rgb(20 184 166)",
        borderWidth: 1,
      },
    ],
  };

  // Opções do gráfico de status (para gráfico de pizza)
  const optionsStatus = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Status das Encomendas",
      },
    },
  };

  // Opções do gráfico de ganhos
  const optionsGanhos = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Ganhos por Mês",
      },
    },
  };

  // Filtrando as encomendas em produção e ordenando por data de entrega
  const encomendasEmProducao = todasEncomendas
    .filter((encomenda) => encomenda.status === "em produção")
    .sort((a, b) => new Date(a.dataEntrega) - new Date(b.dataEntrega));

  // Função para verificar se a data de entrega é urgente
  const isUrgente = (dataEntrega) => {
    const today = new Date();
    const entrega = new Date(dataEntrega);
    const diffTime = entrega - today;
    const diffDays = diffTime / (1000 * 3600 * 24); // Diferença em dias

    return diffDays <= 3; // Se a entrega for dentro de 3 dias, marcar como urgente
  };

  // Função para buscar o nome do cliente pelo ID
  const getNomeCliente = (clienteId) => {
    const cliente = clientes.find((client) => client.id === clienteId);
    return cliente ? cliente.nome : "Cliente não encontrado";
  };

  // Função para formatar a data no formato brasileiro
  const formatarData = (data) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(data).toLocaleDateString("pt-BR", options);
  };

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-6">Dashboard</h1>

      {/* Grid para dividir em duas colunas */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Coluna do gráfico de status (agora gráfico de pizza) */}
        <div className="w-full h-96">
          <div className="relative w-full h-full">
            <Pie data={dataStatus} options={optionsStatus} />{" "}
          </div>
        </div>

        {/* Coluna do gráfico de ganhos */}
        <div className="w-full h-96">
          <div className="relative w-full h-full">
            <Bar data={dataGanhos} options={optionsGanhos} />
          </div>
        </div>
      </div>

      {/* Coluna da lista de encomendas em produção */}
      <div className="overflow-x-auto bg-white rounded-lg mt-8">
        <h2 className="text-xl font-bold mb-4">Encomendas em Produção</h2>
        {encomendasEmProducao.length === 0 ? (
          <p className="text-center text-gray-500">
            Nenhuma encomenda em produção.
          </p>
        ) : (
          <table className="min-w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">Data de Entrega</th>
                <th className="px-4 py-2 text-left">Cliente</th>
                <th className="px-4 py-2 text-left">Descrição</th>
              </tr>
            </thead>
            <tbody>
              {encomendasEmProducao.map((encomenda) => (
                <tr
                  key={encomenda.id}
                  className={
                    isUrgente(encomenda.dataEntrega) ? "text-red-500" : ""
                  }
                >
                  <td className="px-4 py-2">
                    {formatarData(encomenda.dataEntrega)}
                  </td>
                  <td className="px-4 py-2">
                    {getNomeCliente(encomenda.cliente)}
                  </td>
                  <td className="px-4 py-2">{encomenda.descricao}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Main;
