import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import Sidebar from "./components/Sidebar";
import Main from "./components/Main";
import Clientes from "./components/Clientes";
import Produtos from "./components/Produtos";
import Encomendas from "./components/Encomendas";
import Comprovante from "./components/Comprovante";
import Cadastro from "./components/Cadastro";
import Login from "./components/Login";

const App = () => {
  const [user, setUser] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <Router>
      <div className="flex min-h-screen">
        {user && (
          <Sidebar
            isSidebarVisible={isSidebarVisible}
            toggleSidebar={toggleSidebar}
          />
        )}
        <div className="flex-1 p-6 ml-0">
          <Routes>
            <Route
              path="/"
              element={user ? <Main /> : <Navigate to="/login" />}
            />
            <Route
              path="/clientes"
              element={user ? <Clientes /> : <Navigate to="/login" />}
            />
            <Route
              path="/produtos"
              element={user ? <Produtos /> : <Navigate to="/login" />}
            />
            <Route
              path="/encomendas"
              element={user ? <Encomendas /> : <Navigate to="/login" />}
            />
            <Route
              path="/comprovante/:id"
              element={user ? <Comprovante /> : <Navigate to="/login" />}
            />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
