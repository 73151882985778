import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Importa a configuração do Firebase
import { ref, push, set, get, update } from "firebase/database"; // Importa as funções necessárias do Firebase

const Produtos = () => {
  const [produtos, setProdutos] = useState([]);
  const [produto, setProduto] = useState("");
  const [valor, setValor] = useState("");
  const [feedback, setFeedback] = useState("");
  const [editMode, setEditMode] = useState(false); // Variável para controle de edição
  const [editProdutoId, setEditProdutoId] = useState(null); // ID do produto em edição

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!produto || !valor) {
      setFeedback("Preencha todos os campos");
      return;
    }

    try {
      if (editMode) {
        // Atualiza produto existente
        const produtoRef = ref(db, `produtos/${editProdutoId}`);
        await update(produtoRef, {
          produto,
          valor,
        });

        setFeedback("Produto atualizado com sucesso!");
        setEditMode(false);
        setEditProdutoId(null);
      } else {
        // Adiciona um novo produto
        const produtoRef = ref(db, "produtos");
        const newProdutoRef = push(produtoRef); // Usando push() para adicionar um novo item

        await set(newProdutoRef, {
          produto,
          valor,
        });

        setFeedback("Produto cadastrado com sucesso!");
      }

      setProduto("");
      setValor("");
      fetchProdutos(); // Atualiza a lista de produtos após cadastrar ou atualizar
    } catch (error) {
      console.error("Erro ao cadastrar/atualizar produto:", error);
      setFeedback("Erro ao cadastrar/atualizar produto. Verifique o console.");
    }
  };

  // Função para buscar os produtos do Firebase
  const fetchProdutos = async () => {
    const produtosRef = ref(db, "produtos");
    try {
      const snapshot = await get(produtosRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const produtosList = Object.keys(data)
          .map((key) => ({
            id: key,
            produto: data[key].produto,
            valor: data[key].valor,
          }))
          .sort((a, b) => a.produto.localeCompare(b.produto));
        setProdutos(produtosList);
      } else {
        console.log("Nenhum produto encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    }
  };

  // Função para preencher o formulário de edição
  const handleEdit = (produto) => {
    setProduto(produto.produto);
    setValor(produto.valor);
    setEditMode(true);
    setEditProdutoId(produto.id); // Salva o id do produto para a edição
  };

  // Carregar os produtos quando o componente for montado
  useEffect(() => {
    fetchProdutos();
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">
        {editMode ? "Editar Produto" : "Cadastrar Produto"}
      </h1>
      <form onSubmit={handleSubmit} className="mb-6">
        <div>
          <label className="block mb-2 ">Produto</label>
          <input
            type="text"
            value={produto}
            onChange={(e) => setProduto(e.target.value)}
            className="p-2 w-full border rounded mb-4 bg-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div>
          <label className="block mb-2 ">Valor</label>
          <input
            type="text"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
            className="p-2 w-full border rounded mb-4 bg-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="Valor do produto"
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 text-white bg-teal-600  rounded hover:bg-teal-700"
        >
          {editMode ? "Atualizar Produto" : "Cadastrar Produto"}
        </button>
      </form>

      {feedback && <p>{feedback}</p>}

      {/* Tabela de produtos */}
      <h2 className="text-2xl font-bold mb-4">Lista de Produtos</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {produtos.map((produto) => (
          <div
            key={produto.id}
            className="card p-4 shadow-md bg-white rounded flex flex-col border-2 border-gray-300"
            style={{ borderWidth: "1px" }}
          >
            <h3 className="font-semibold text-lg">{produto.produto}</h3>
            <p className="mt-2">
              <strong>Valor:</strong> R$: {produto.valor}
            </p>
            <div className="mt-4">
              <button
                onClick={() => handleEdit(produto)} // Função de edição
                className="px-4 py-2 bg-teal-600 hover:bg-teal-700 text-white rounded"
              >
                Editar
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Produtos;
