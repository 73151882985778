import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { ref, push, set, get, update } from "firebase/database";

const Encomendas = () => {
  const formatarData = (dataString) => {
    const [ano, mes, dia] = dataString.split("-");
    return `${dia}/${mes}/${ano}`;
  };
  const [clientes, setClientes] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [encomenda, setEncomenda] = useState({
    cliente: "",
    produtos: [],
    dataPedido: "",
    dataEntrega: "",
    descricao: "",
    status: "em produção",
  });
  const [feedback, setFeedback] = useState("");
  const [todasEncomendas, setTodasEncomendas] = useState([]);

  const [mostrarTodas, setMostrarTodas] = useState(false);
  const encomendasFiltradas = todasEncomendas.filter((encomenda) => {
    return mostrarTodas || encomenda.status !== "pago";
  });

  const fetchClientes = async () => {
    const clientesRef = ref(db, "clientes");
    try {
      const snapshot = await get(clientesRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const clientesList = Object.keys(data).map((key) => ({
          id: key,
          nome: data[key].nome,
        }));
        setClientes(clientesList);
      } else {
        console.log("Nenhum cliente encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
    }
  };

  const fetchProdutos = async () => {
    const produtosRef = ref(db, "produtos");
    try {
      const snapshot = await get(produtosRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const produtosList = Object.keys(data).map((key) => ({
          id: key,
          produto: data[key].produto,
          valor: parseFloat(data[key].valor),
        }));
        setProdutos(produtosList);
      } else {
        console.log("Nenhum produto encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    }
  };

  const fetchEncomendas = async () => {
    const encomendasRef = ref(db, "encomendas");
    try {
      const snapshot = await get(encomendasRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const encomendasList = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        // Ordena as encomendas por data de entrega (em ordem decrescente)
        encomendasList.sort((a, b) => {
          const dateA = new Date(a.dataEntrega);
          const dateB = new Date(b.dataEntrega);
          return dateB - dateA; // Alterado para ordem decrescente
        });

        setTodasEncomendas(encomendasList);
      } else {
        console.log("Nenhuma encomenda encontrada");
      }
    } catch (error) {
      console.error("Erro ao buscar encomendas:", error);
    }
  };

  useEffect(() => {
    fetchClientes();
    fetchProdutos();
    fetchEncomendas();
  }, []);

  const handleAddProduto = () => {
    setEncomenda({
      ...encomenda,
      produtos: [...encomenda.produtos, { produtoId: "", quantidade: 1 }],
    });
  };

  const handleProdutoChange = (index, field, value) => {
    const newProdutos = [...encomenda.produtos];
    newProdutos[index][field] = value;
    setEncomenda({ ...encomenda, produtos: newProdutos });
  };

  const calcularSubtotal = () => {
    return encomenda.produtos.reduce((total, item) => {
      const produtoSelecionado = produtos.find(
        (produto) => produto.id === item.produtoId
      );
      return produtoSelecionado
        ? total + produtoSelecionado.valor * item.quantidade
        : total;
    }, 0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !encomenda.cliente ||
      encomenda.produtos.length === 0 ||
      !encomenda.dataPedido ||
      !encomenda.dataEntrega
    ) {
      setFeedback("Preencha todos os campos");
      return;
    }

    try {
      const encomendaRef = ref(db, "encomendas");
      const newEncomendaRef = push(encomendaRef);

      await set(newEncomendaRef, {
        ...encomenda,
        subtotal: calcularSubtotal(),
      });

      setFeedback("Encomenda cadastrada com sucesso!");
      setEncomenda({
        cliente: "",
        produtos: [],
        dataPedido: "",
        dataEntrega: "",
        descricao: "",
        status: "em produção",
      });
      fetchEncomendas();
    } catch (error) {
      console.error("Erro ao cadastrar encomenda:", error);
      setFeedback("Erro ao cadastrar encomenda. Verifique o console.");
    }
  };

  const handleStatusChange = async (encomendaId, status) => {
    try {
      const encomendaRef = ref(db, `encomendas/${encomendaId}`);
      await update(encomendaRef, { status });
      fetchEncomendas();
    } catch (error) {
      console.error("Erro ao atualizar status:", error);
    }
  };

  // Função para obter o nome do cliente baseado no ID
  const getNomeCliente = (clienteId) => {
    const cliente = clientes.find((cliente) => cliente.id === clienteId);
    return cliente ? cliente.nome : "Cliente não encontrado";
  };

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Cadastrar Encomenda</h1>
      <form onSubmit={handleSubmit} className="mb-6">
        {/* Formulário de cadastro de encomenda */}
        <div>
          <label className="block mb-2">Data do Pedido</label>
          <input
            type="date"
            value={encomenda.dataPedido}
            onChange={(e) =>
              setEncomenda({ ...encomenda, dataPedido: e.target.value })
            }
            className="p-2 w-full border rounded mb-4 bg-white"
          />
        </div>

        <div>
          <label className="block mb-2">Cliente</label>
          <select
            value={encomenda.cliente}
            onChange={(e) =>
              setEncomenda({ ...encomenda, cliente: e.target.value })
            }
            className="p-2 w-full border rounded mb-4 bg-white"
          >
            <option value="">Selecione o cliente</option>
            {clientes
              .slice()
              .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordena os clientes alfabeticamente pelo nome
              .map((cliente) => (
                <option key={cliente.id} value={cliente.id}>
                  {cliente.nome}
                </option>
              ))}
          </select>
        </div>

        <div>
          <label className="block mb-2">Produtos</label>
          {encomenda.produtos.map((item, index) => (
            <div key={index} className="mb-4">
              <select
                value={item.produtoId}
                onChange={(e) =>
                  handleProdutoChange(index, "produtoId", e.target.value)
                }
                className="p-2 w-1/2 border rounded mr-2 bg-white"
              >
                <option value="">Selecione o produto</option>
                {produtos
                  .slice()
                  .sort((a, b) => a.produto.localeCompare(b.produto))
                  .map((produto) => (
                    <option key={produto.id} value={produto.id}>
                      {produto.produto} - R$
                      {parseFloat(produto.valor).toFixed(2)}
                    </option>
                  ))}
              </select>
              <input
                type="number"
                value={item.quantidade}
                onChange={(e) =>
                  handleProdutoChange(index, "quantidade", e.target.value)
                }
                min="1"
                className="p-2 w-1/4 border rounded bg-white"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddProduto}
            className="p-2 bg-teal-600 rounded hover:bg-teal-700 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 5v14m7-7H5"
              />
            </svg>
          </button>
        </div>

        <div>
          <label className="block mb-2">Data de Entrega</label>
          <input
            type="date"
            value={encomenda.dataEntrega}
            onChange={(e) =>
              setEncomenda({ ...encomenda, dataEntrega: e.target.value })
            }
            className="p-2 w-full border rounded mb-4 bg-white"
          />
        </div>

        <div>
          <label className="block mb-2">Descrição</label>
          <textarea
            value={encomenda.descricao}
            onChange={(e) =>
              setEncomenda({ ...encomenda, descricao: e.target.value })
            }
            className="p-2 w-full border rounded mb-4 bg-white"
          />
        </div>

        <button
          type="submit"
          className="p-3 bg-teal-600 rounded hover:bg-teal-700 text-white"
        >
          Adicionar Encomenda
        </button>

        {feedback && <p className="mt-4 text-green-600">{feedback}</p>}
      </form>

      <hr></hr>

      <h2 className="text-2xl font-semibold">Encomendas Cadastradas</h2>

      <label>
        <input
          className="my-4 mr-2"
          type="checkbox"
          checked={mostrarTodas}
          onChange={() => setMostrarTodas(!mostrarTodas)}
        />
        Mostrar todas as encomendas
      </label>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {encomendasFiltradas.map((encomenda) => (
          <div
            key={encomenda.id}
            className={`card p-4 shadow-md bg-white rounded flex flex-col border-teal-500 border-2`}
          >
            <h3 className="font-semibold text-lg">
              {getNomeCliente(encomenda.cliente)}
            </h3>
            <div className="gap-4">
              <p>
                <strong>Data de Pedido:</strong>{" "}
                {formatarData(encomenda.dataPedido)}
              </p>
              <p>
                <strong>Data de Entrega:</strong>{" "}
                {formatarData(encomenda.dataEntrega)}
              </p>
              <p>
                <strong>Descrição:</strong> {encomenda.descricao}
              </p>

              <p>
                <strong>Produtos:</strong>
              </p>
              <ul className="list-disc pl-5">
                {encomenda.produtos.map((item, index) => {
                  const produtoSelecionado = produtos.find(
                    (produto) => produto.id === item.produtoId
                  );
                  return produtoSelecionado ? (
                    <li key={index}>
                      {item.quantidade}x {produtoSelecionado.produto}
                    </li>
                  ) : (
                    <li key={index}>Produto não encontrado</li>
                  );
                })}
              </ul>

              <p>
                <strong>Subtotal:</strong> R$ {encomenda.subtotal.toFixed(2)}
              </p>

              <p className="col-span-2">
                <strong>Status:</strong>{" "}
                <select
                  value={encomenda.status}
                  onChange={(e) =>
                    handleStatusChange(encomenda.id, e.target.value)
                  }
                  className="p-2 bg-white border rounded"
                >
                  <option value="em produção">Em produção</option>
                  <option value="finalizado">Finalizado</option>
                  <option value="pago">Pago</option>
                </select>
              </p>

              {/* Botão Comprovante */}
              <button
                onClick={() =>
                  (window.location.href = `/comprovante/${encomenda.id}`)
                }
                className="mt-4 p-2 bg-teal-600 text-white rounded hover:bg-teal-700"
              >
                Comprovante
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Encomendas;
