import React, { useState } from "react";
import { auth } from "../firebase"; // Importando a configuração do Firebase Authentication
import { createUserWithEmailAndPassword } from "firebase/auth"; // Função de cadastro

const Cadastro = () => {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [feedback, setFeedback] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !senha) {
      setFeedback("Preencha todos os campos!");
      return;
    }

    try {
      // Cadastrando o usuário com email e senha
      await createUserWithEmailAndPassword(auth, email, senha);
      setFeedback("Usuário cadastrado com sucesso!");
      setEmail("");
      setSenha("");
    } catch (error) {
      console.error("Erro ao cadastrar usuário:", error);
      setFeedback("Erro ao cadastrar usuário. Tente novamente.");
    }
  };

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Cadastro de Usuário</h1>
      <form onSubmit={handleSubmit} className="mb-6">
        <div>
          <label className="block mb-2">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-2 w-full border rounded mb-4"
            placeholder="Digite seu e-mail"
          />
        </div>
        <div>
          <label className="block mb-2">Senha</label>
          <input
            type="password"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            className="p-2 w-full border rounded mb-4"
            placeholder="Digite sua senha"
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700"
        >
          Cadastrar
        </button>
      </form>
      {feedback && <p>{feedback}</p>}
    </div>
  );
};

export default Cadastro;
