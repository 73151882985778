// Importando funções do Firebase
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth"; // Para autenticação

// Configuração do Firebase (suas credenciais)
const firebaseConfig = {
  apiKey: "AIzaSyAqtrjo-ZHZj7K3WHhyqTLRG3c1fipsFeA",
  authDomain: "sistema-encomendas-56e8f.firebaseapp.com",
  databaseURL: "https://sistema-encomendas-56e8f-default-rtdb.firebaseio.com",
  projectId: "sistema-encomendas-56e8f",
  storageBucket: "sistema-encomendas-56e8f.firebasestorage.app",
  messagingSenderId: "1007480610856",
  appId: "1:1007480610856:web:8d4c059f447f7f03a2d51d",
};

// Inicializando o Firebase
const app = initializeApp(firebaseConfig);

// Obter referências
const db = getDatabase(app);
const auth = getAuth(app); // Referência de autenticação

export { db, auth }; // Exportando para ser usado em outros componentes
