import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebase"; // Importe o Firebase
import { signOut } from "firebase/auth"; // Importe a função signOut

const Sidebar = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Hook para navegação do React Router

  const isActive = (path) => location.pathname === path;

  const handleLogout = async () => {
    try {
      // Desloga o usuário
      await signOut(auth);
      // Após deslogar, redireciona para a página de login
      navigate("/login");
    } catch (error) {
      console.error("Erro ao deslogar:", error.message);
    }
  };

  return (
    <>
      <button
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
        className="lg:hidden fixed top-4 right-4 p-2 bg-teal-600 rounded-full z-50 print:hidden"
        aria-label={
          isSidebarVisible ? "Fechar menu lateral" : "Abrir menu lateral"
        }
      >
        {isSidebarVisible ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fff"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fff"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </button>

      <nav
        className={`${
          isSidebarVisible ? "block" : "hidden"
        } lg:block w-64 bg-white p-4 h-screen fixed lg:static top-0 left-0 z-40 transition-all`}
      >
        <h1 className="text-2xl font-bold text-center mb-6">Menu</h1>
        <ul className="space-y-2">
          <li>
            <Link
              to="/"
              className={`block px-4 py-2 rounded-lg ${
                isActive("/")
                  ? "bg-teal-500 text-white"
                  : "text-gray-700 hover:text-teal-500"
              } transition-colors`}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/clientes"
              className={`block px-4 py-2 rounded-lg ${
                isActive("/clientes")
                  ? "bg-teal-500 text-white"
                  : "text-gray-700 hover:text-teal-500"
              } transition-colors`}
            >
              Clientes
            </Link>
          </li>
          <li>
            <Link
              to="/produtos"
              className={`block px-4 py-2 rounded-lg ${
                isActive("/produtos")
                  ? "bg-teal-500 text-white"
                  : "text-gray-700 hover:text-teal-500"
              } transition-colors`}
            >
              Produtos
            </Link>
          </li>
          <li>
            <Link
              to="/encomendas"
              className={`block px-4 py-2 rounded-lg ${
                isActive("/encomendas")
                  ? "bg-teal-500 text-white"
                  : "text-gray-700 hover:text-teal-500"
              } transition-colors`}
            >
              Encomendas
            </Link>
          </li>
          {/* Link de Logout */}
          <li>
            <button
              onClick={handleLogout}
              className="block w-full px-4 py-2 text-red-600 hover:bg-red-100 rounded-lg transition-colors text-left"
              aria-label="Deslogar"
            >
              Logout
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
