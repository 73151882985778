import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { ref, get } from "firebase/database";

const Comprovante = () => {
  const { id } = useParams(); // Captura o ID da encomenda
  const [detalhesEncomenda, setDetalhesEncomenda] = useState(null);
  const [nomeCliente, setNomeCliente] = useState("");
  const [nomesProdutos, setNomesProdutos] = useState({});

  useEffect(() => {
    const fetchEncomenda = async () => {
      const encomendaRef = ref(db, `encomendas/${id}`);
      try {
        const snapshot = await get(encomendaRef);
        if (snapshot.exists()) {
          const encomenda = snapshot.val();
          setDetalhesEncomenda(encomenda);

          // Buscar nome do cliente
          const clienteRef = ref(db, `clientes/${encomenda.cliente}`);
          const clienteSnapshot = await get(clienteRef);
          if (clienteSnapshot.exists()) {
            setNomeCliente(clienteSnapshot.val().nome);
          }

          // Buscar nomes dos produtos
          const produtosRef = encomenda.produtos.map(async (produto) => {
            const produtoSnapshot = await get(
              ref(db, `produtos/${produto.produtoId}`)
            );
            return {
              id: produto.produtoId,
              nome: produtoSnapshot.exists()
                ? produtoSnapshot.val().produto
                : "Desconhecido",
            };
          });

          const produtosNomes = await Promise.all(produtosRef);

          // Verifique aqui se os nomes estão sendo armazenados corretamente
          console.log(produtosNomes);

          const nomesMap = produtosNomes.reduce((map, item) => {
            map[item.id] = item.nome;
            return map;
          }, {});

          setNomesProdutos(nomesMap);
        } else {
          console.error("Encomenda não encontrada");
        }
      } catch (error) {
        console.error("Erro ao buscar encomenda:", error);
      }
    };

    fetchEncomenda();
  }, [id]);

  if (!detalhesEncomenda) {
    return <p>Carregando...</p>;
  }

  // Formatador de datas no padrão dd/mm/aaaa
  const formatarData = (dataISO) => {
    const data = new Date(dataISO);
    return data.toLocaleDateString("pt-BR");
  };

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Detalhes da Encomenda</h1>
      <p>
        <strong>ID da Encomenda:</strong> {id}
      </p>
      <p>
        <strong>Cliente:</strong> {nomeCliente}
      </p>
      <p>
        <strong>Data do Pedido:</strong>{" "}
        {formatarData(detalhesEncomenda.dataPedido)}
      </p>
      <p>
        <strong>Produtos:</strong>
      </p>
      <ul>
        {detalhesEncomenda.produtos.map((produto, index) => (
          <li key={index}>
            {produto.quantidade}x{" "}
            {nomesProdutos[produto.produtoId] || produto.produtoId}
          </li>
        ))}
      </ul>
      <p>
        <strong>Descrição:</strong> {detalhesEncomenda.descricao}
      </p>
      <p>
        <strong>Subtotal:</strong> R$ {detalhesEncomenda.subtotal.toFixed(2)}
      </p>

      <button
        onClick={() => window.print()}
        className="mt-4 p-2 bg-blue-600 text-white rounded hover:bg-blue-700 print:hidden"
      >
        Imprimir
      </button>
    </div>
  );
};

export default Comprovante;
