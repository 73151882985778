import React, { useState, useEffect } from "react";
import { auth } from "../firebase"; // Importando o Firebase
import { signInWithEmailAndPassword } from "firebase/auth"; // Função de login
import { Navigate } from "react-router-dom"; // Importando o Navigate

const Login = () => {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [feedback, setFeedback] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Verifica se o usuário está logado assim que o componente for montado
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Atualiza o estado com o usuário logado
    });

    return () => unsubscribe(); // Limpa a assinatura quando o componente for desmontado
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !senha) {
      setFeedback("Preencha todos os campos!");
      return;
    }

    try {
      // Fazendo login com email e senha
      await signInWithEmailAndPassword(auth, email, senha);
      setFeedback("Login realizado com sucesso!");
      setEmail("");
      setSenha("");
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      setFeedback("Erro ao fazer login. Verifique suas credenciais.");
    }
  };

  // Se o usuário estiver logado, redireciona para a Home
  if (user) {
    return <Navigate to="/" />; // Redireciona para a Home
  }

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">Login</h1>
      <form onSubmit={handleSubmit} className="mb-6">
        <div>
          <label className="block mb-2">Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-2 w-full border rounded mb-4"
            placeholder="Digite seu e-mail"
          />
        </div>
        <div>
          <label className="block mb-2">Senha</label>
          <input
            type="password"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
            className="p-2 w-full border rounded mb-4"
            placeholder="Digite sua senha"
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700"
        >
          Entrar
        </button>
      </form>
      {feedback && <p>{feedback}</p>}
    </div>
  );
};

export default Login;
