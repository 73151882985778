import React, { useState, useEffect } from "react";
import { db } from "../firebase"; // Importa a configuração do Firebase
import { ref, push, set, get, update } from "firebase/database"; // Importa as funções necessárias do Firebase

const Clientes = () => {
  const [clientes, setClientes] = useState([]);
  const [nome, setNome] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [feedback, setFeedback] = useState("");
  const [editMode, setEditMode] = useState(false); // Variável para controle de edição
  const [editClienteId, setEditClienteId] = useState(null); // ID do cliente em edição

  // Função de máscara de WhatsApp
  const handleWhatsAppChange = (e) => {
    let value = e.target.value;
    let cleaned = value.replace(/\D/g, ""); // Remove tudo que não é número

    if (cleaned.length <= 10) {
      cleaned = cleaned.replace(/^(\d{2})(\d)/, "($1) $2");
    }
    if (cleaned.length > 10) {
      cleaned = cleaned.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
    }

    setWhatsapp(cleaned);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!nome || !whatsapp) {
      setFeedback("Preencha todos os campos");
      return;
    }

    try {
      if (editMode) {
        // Atualiza cliente existente
        const clienteRef = ref(db, `clientes/${editClienteId}`);
        await update(clienteRef, {
          nome,
          whatsapp,
        });

        setFeedback("Cliente atualizado com sucesso!");
        setEditMode(false);
        setEditClienteId(null);
      } else {
        // Adiciona um novo cliente
        const clienteRef = ref(db, "clientes");
        const newClienteRef = push(clienteRef); // Usando push() para adicionar um novo item

        await set(newClienteRef, {
          nome,
          whatsapp,
        });

        setFeedback("Cliente cadastrado com sucesso!");
      }

      setNome("");
      setWhatsapp("");
      fetchClientes(); // Atualiza a lista de clientes após cadastrar ou atualizar
    } catch (error) {
      console.error("Erro ao cadastrar/atualizar cliente:", error);
      setFeedback("Erro ao cadastrar/atualizar cliente. Verifique o console.");
    }
  };

  // Função para buscar os clientes do Firebase
  const fetchClientes = async () => {
    const clientesRef = ref(db, "clientes");
    try {
      const snapshot = await get(clientesRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const clientesList = Object.keys(data).map((key) => ({
          id: key,
          nome: data[key].nome,
          whatsapp: data[key].whatsapp,
        }));
        setClientes(clientesList);
      } else {
        console.log("Nenhum cliente encontrado");
      }
    } catch (error) {
      console.error("Erro ao buscar clientes:", error);
    }
  };

  // Função para preencher o formulário de edição
  const handleEdit = (cliente) => {
    setNome(cliente.nome);
    setWhatsapp(cliente.whatsapp);
    setEditMode(true);
    setEditClienteId(cliente.id); // Salva o id do cliente para a edição
  };

  // Carregar os clientes quando o componente for montado
  useEffect(() => {
    fetchClientes();
  }, []);

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6">
        {editMode ? "Editar Cliente" : "Cadastrar Cliente"}
      </h1>
      <form onSubmit={handleSubmit} className="mb-6">
        <div>
          <label className="block mb-2">Nome</label>
          <input
            type="text"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            className="p-2 w-full border rounded mb-4 bg-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div>
          <label className="block mb-2">WhatsApp</label>
          <input
            type="text"
            value={whatsapp}
            onChange={handleWhatsAppChange} // Aplica a função de máscara
            className="p-2 w-full border rounded mb-4 bg-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="(xx) x xxxx-xxxx"
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 text-white bg-teal-600 rounded hover:bg-teal-700"
        >
          {editMode ? "Atualizar Cliente" : "Cadastrar Cliente"}
        </button>
      </form>

      {feedback && <p>{feedback}</p>}

      {/* Exibindo os clientes em cards */}
      <h2 className="text-2xl font-bold mb-4">Lista de Clientes</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {clientes.map((cliente) => (
          <div
            key={cliente.id}
            className="card p-4 shadow-md bg-white rounded flex flex-col border-2 border-gray-300"
            style={{ borderWidth: "1px" }}
          >
            <h3 className="font-semibold text-lg">{cliente.nome}</h3>
            <p className="mt-2">
              <strong>WhatsApp:</strong> {cliente.whatsapp}
            </p>
            <div className="mt-4">
              <button
                onClick={() => handleEdit(cliente)} // Função de edição
                className="px-4 py-2 bg-teal-600 hover:bg-teal-700 text-white rounded"
              >
                Editar
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clientes;
